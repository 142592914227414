export default class LeitorArquivo {
  extensaoArquivo = '.csv';

  setExtensaoArquivo = extensaoArquivo => {
    this.extensaoArquivo = extensaoArquivo;
  };

  lerArquivo = (files, callback) => {
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        callback({
          concluido: true,
          fileAsBinaryString
        });
      };
      reader.onabort = () => callback({ concluido: false, motivo: 'Upload abortado' });
      reader.onerror = () => {
        callback({
          concluido: false,
          motivo: `Houve um erro com o aquivo ${this.extensaoArquivo.toUpperCase()}`
        });
      };
      reader.readAsText(file, 'ISO-8859-1');
    });
  };

  lerArquivoKML = (files, callback) => {
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        callback({
          concluido: true,
          fileAsBinaryString
        });
      };
      reader.onabort = () => callback({ concluido: false, motivo: 'Upload abortado' });
      reader.onerror = () => {
        callback({
          concluido: false,
          motivo: `Houve um erro com o aquivo ${this.extensaoArquivo.toUpperCase()}`
        });
      };
      reader.readAsText(file, 'utf-8');
    });
  };

  validaInputArquivo = (accepted, rejected) => {
    let resultado = {
      entradaArquivoValido: false,
      mensagem: ''
    };
    if (accepted.length > 1) {
      resultado.mensagem = `Somente 1 arquivo pode ser importado por vez`;
    } else if (accepted.length) {
      resultado.entradaArquivoValido = true;
    } else if (rejected.length) {
      resultado.mensagem = `Somente arquivos ${this.extensaoArquivo.toUpperCase()} são suportados`;
    }
    return resultado;
  };
}
